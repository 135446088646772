.landing-page {
  height: 100vh;
  background-image: url("../../assets/plane.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.overlay {
  background-color: rgba(96, 93, 236, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  color: white;
  animation: fadeIn 1s ease forwards; /* Fade-in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Move content slightly up */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fancy-text {
  position: relative;
  display: inline-block;
}

.fancy-text::before {
  content: "";
  position: absolute;
  top: -20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 200px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  border: 2px solid white;
  border-radius: 50%;
  z-index: -1;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  animation: scaleIn 0.5s ease forwards; /* Scale-in animation */
}

@keyframes scaleIn {
  from {
    transform: scale(0.8); /* Start from slightly smaller size */
  }
  to {
    transform: scale(1);
  }
}

.btn:hover {
  background-color: #0056b3;
}
.circle-text {
  position: relative;
  display: inline-block;
}

.circle-text:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px; /* Adjust size of the circle */
  height: 200px; /* Adjust size of the circle */
  background-color: rgba(255, 255, 255, 0.1); /* Adjust color and opacity of the circle */
  border-radius: 50%;
  z-index: -1;
}

.circle-text .text {
  position: relative;
  z-index: 1;
}

/* Additional styling */
.circle-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add shadow to text */
  animation: glow 2s ease-in-out infinite alternate; /* Add glowing effect */
}

@keyframes glow {
  from {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  to {
    text-shadow: 2px 2px 8px rgba(128, 74, 228, 0.8);
  }
}
